import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyCFJ444v7Mhj-5T5hnDqoE02fEJMnNkxJY",
  authDomain: "wah-fai-company.firebaseapp.com",
  databaseURL:
    "https://wah-fai-company-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "wah-fai-company",
  storageBucket: "wah-fai-company.appspot.com",
  messagingSenderId: "231623580610",
  appId: "1:231623580610:web:ab479828b0a1572c6d945b",
  measurementId: "G-3GB1BN65E5",
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "Wah Fai Company"; //公司名稱
      },
      get regFormDomain() {
        return "https://wahfai.xyz/";
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor = "#e8f2fa";

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor = "#000";

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export { firebaseConfig, panelColor, sideBarColor };
